// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import {v4 as uuidv4} from "uuid";
import AlarmIcon, { getAlarmName } from "components/AlarmIcon";
import MDBox from "components/MDBase/MDBox";
import MDBadge from "../../../MDBase/MDBadge";
import { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";

export const convertDetectionName = (key) => {
  switch (key) {
    case "back_flow": return "backFlowDetection";
    case "battery": return "batteryLow";
    case "burst": return "burstDetection";
    case "leakage": return "leakageDetection";
    case "magnet": return "magnetTamper";
    case "no_flow": return "noFlowDetection";
    case "nrt": return "nonRealtimeBackFlowDetection";
    case "reboot": return "rebootDetection";
    case "sensor": return "faultySensor";
    case "tilt": return "tiltDetection";
    case "wire_cut": return "wireCutDetection";
    default: return "";
  }
}

function AlarmCounterCell({ data }) {
  const [alarms, setAlarms] = useState([]);

  useEffect(() => {
    processData();
  }, [data]);

  const processData = () => {
    const list = []
    for (let key in data) {
      if (!isNaN(data[key]) && convertDetectionName(key) !== "") {
        if (data[key]>0) {
          list.push({
            alarm_name: convertDetectionName(key),
            count: data[key]
          })
        }
      }
    }
    setAlarms(list);
  }

  return (
    (alarms.length > 0) ? (
      <MDBox display="flex" alignItems="center" sx={{gap:"1rem"}}>
        {
          alarms.map((alarm) => {
            if (alarm.count === 1) {
              return (
                <Tooltip title={getAlarmName(alarm.alarm_name)} key={uuidv4()}>
                  <MDBox>
                    <AlarmIcon size="1.5rem" alarm={alarm.alarm_name} displayOnly/>
                  </MDBox>
                </Tooltip>
              )
            } else {
              console.log(alarm.count);
              return (
                <Tooltip title={`${getAlarmName(alarm.alarm_name)} x ${alarm.count}`} key={uuidv4()}>
                  <MDBadge badgeContent={alarm.count} color="error" size="xs" indicator variant="contained" >
                    <AlarmIcon size="1.5rem" alarm={alarm.alarm_name} displayOnly/>
                  </MDBadge>
                </Tooltip>
              )
            }
          })
        }
      </MDBox>
    ): null
  );
}

// Setting default value for the props of CustomerCell
AlarmCounterCell.defaultProps = {
  data: {}
};

// Typechecking props for the CustomerCell
AlarmCounterCell.propTypes = {
  data: PropTypes.object
};

export default AlarmCounterCell;
